import { queries } from "../../../breakpoints"
import styled, { css } from 'styled-components';

export const StyledIllustrationHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    position: relative;
`;


export const Preheader = styled.h3`
    font-weight: bold;
    text-transform: uppercase;
    color: #aeaeae;
    letter-spacing: 4.14px;
    font-size: clamp(var(--fontSize-1), 1.15vw, 3rem);
    margin: 0;
`;

export const Headline = styled.h1`
    font-weight: bold;  
    max-width: 650px;
    margin: 0px auto 7px;
    font-size: clamp(var(--fontSize-6), 3.5vw, 7rem);
    
    &.extra-spacing-headline{
        letter-spacing: 0px;
    }

  ${props => props.theme === 'catering' && css`
      text-align: left;
      margin: 0 0 7px 0;
      max-width: 420px;
      font-size: clamp(var(--fontSize-7), 3.5vw, 7rem) !important;
    `}
`;

export const Subheadline = styled.p`
    margin-bottom: 30px;
    font-size: var(--fontSize-2);
    ${props => props.theme === 'catering' && css`
      text-align: left;
      font-size: var(--fontSize-1);
      max-width: 312px;
    `}
`;

export const Footnote = styled.p`
    margin-top: 30px;
  ${props => props.theme === 'catering' && css`
      text-align: left;
      font-size: var(--fontSize-0);
      max-width: 312px;
      font-style: italic;
    `}
`;

export const Container = styled.div`
    min-height: 300px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    @media (${queries.max_break_sm}){
        grid-template-columns: 1fr;
        padding:50px 25px 0px;
    }

    ${ props => props.theme === 'catering' && css`
      display: flex;
      align-items: flex-start;
      max-width: 895px;
    `}


  ${( mobileSwitchEarlier ) => {
    if( mobileSwitchEarlier ) {
      return `@media (max-width: 1000px){ 
                display:none;
            }
            `
    }
  }}
`;

export const Illustration = styled.img`
    width:100%;
    max-width: 300px;
    align-self: flex-end;
    @media (${queries.max_break_sm}){
        display:none;
    }
`;

export const LeftIllustration = styled(Illustration)`
`;

export const Content = styled.div`
    max-width: 900px;
    padding: 40px 0%;
    margin:0 auto;
    @media (${queries.max_break_sm}){
        margin-bottom: 20px;
    }

  ${props => props.theme === 'catering' && css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
    `}
`;

export const RightIllustration = styled(Illustration)`
  justify-self: end; 
`;

export const MobileIllustration = styled.img`
    width:100%;
    display:none;
  
    ${( mobileSwitchEarlier ) => {
        if( mobileSwitchEarlier ) {
            return `@media (max-width: 1000px){ 
                display:block;
                max-width: 100%;
                margin: 0 auto; 
            }
            `
        }
    }}
  
    @media (${queries.max_break_sm}){
        display:block;
        max-width: 300px;
        margin: 0 auto; 
    }
   
`;
