import { Container, Content, Footnote, Headline, LeftIllustration, MobileIllustration, Preheader, RightIllustration, StyledIllustrationHeader, Subheadline } from "./IllustrationHeader.styles"
import React, { useContext } from 'react';

import { AnalyticsContext } from "../../../context"
import Button from '../Button'
import PropTypes from 'prop-types';
import { getPageSlug } from '../../../../helpers';

const IllustrationHeader = (props) => {
  const { handleTealiumEvent } = useContext(AnalyticsContext)

  return(
    <StyledIllustrationHeader theme={props.theme} style={{ background: props.background, color: props.textColor, ...props.style }} hasChildren={props.children}>
      <Container>
        {props.leftSideIllustration && <LeftIllustration src={props.leftSideIllustration} alt={props.altLeftSide}/>}
        <Content>
          {props.preheader && <Preheader>{props.preheader}</Preheader>}
          {props.headline && <Headline style={{ color: props.textColor }} className={props.headlineClassName}>{props.headline}</Headline>}
          {props.subheadline && <Subheadline style={{ color: props.textColor }}>{props.subheadline}</Subheadline>}
          {props.ctaLink &&
            <Button
            to={props.ctaLink}
            onClick={() => {
              handleTealiumEvent( { eventcategory: props.tealiumEventCategory, eventaction: props.tealiumEventAction, eventlabel: props.tealiumEventLabel } )
            }}
            >
              {props.ctaText}
            </Button>
          }
          {props.footnote && <Footnote style={{ color: props.textColor }}>{props.footnote}</Footnote>}
        </Content>
        {props.rightSideIllustration && <RightIllustration src={props.rightSideIllustration} alt={props.altRightSide} />}
        {props.mobileIllustration && <MobileIllustration src={props.mobileIllustration} alt={props.altMobile}/>}
      </Container>
    </StyledIllustrationHeader>
  )
};

IllustrationHeader.propTypes = {
  preheader: PropTypes.string,
  headlineClassName: PropTypes.string,
  headline: PropTypes.string,
  subheadline: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  footnote:PropTypes.string,
  background: PropTypes.string,
  textColor: PropTypes.string,
  style: PropTypes.shape({}),
  theme: PropTypes.string,
  leftSideIllustration: PropTypes.string,
  altLeftSide: PropTypes.string,
  rightSideIllustration: PropTypes.string,
  altRightSide: PropTypes.string,
  mobileIllustration: PropTypes.string,
  altMobile: PropTypes.string,
  tealiumEventCategory: PropTypes.string,
  tealiumEventAction: PropTypes.string,
  tealiumEventLabel: PropTypes.string,
};

IllustrationHeader.defaultProps = {
  preheader: null,
  headlineClassName: "",
  headline: null,
  subheadline: null,
  ctaLink: null,
  ctaText: "Get started",
  footnote:"",
  background: "radial-gradient(circle, #612889 0%, var(--color-primary-darker) 100%)",
  textColor: "white",
  style: null,
  theme: "default",
  leftSideIllustration: null,
  altLeftSide: "",
  rightSideIllustration: null,
  altRightSide: "",
  mobileIllustration: null,
  altMobile: "",
  tealiumEventCategory: `${getPageSlug()} interaction`,
  tealiumEventAction: "get started_cta",
  tealiumEventLabel: "hero banner",
};

export default IllustrationHeader;
