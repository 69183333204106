import CardsSection from "../components/sections/CardsSection/CardsSection"
import CtaSection from "../components/sections/CtaSection"
import IllustrationHeader from "../components/global/IllustrationHeader"
import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection"
import React from "react"
import ValuePropsSection from "../components/sections/ValuePropsSection"

const cards = [
  {
    title: "Hybrid Teams",
    description: "Keep your hungry teams happy, whether they're in the office, at home or both.",
    link:"/hybrid-teams/",
    imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1661441639/Grubhub%20Corporate%20Website/Hybrid%20Teams%20Page/HybridTeams.jpg",
    linkText: "Learn more"
  },
  {
    title: "Corporate Perks",
    description: "Discover how Grubhub Corporate can fit into your employee benefits package.",
    link:"/perks/",
    imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626796615/Grubhub%20Corporate%20Website/2021/Photography%20Elements/2-OrderingOccassion-Homepage/EXPORTS-Photography-CorpOccasionsHomepage-450x220-CoproratePerks.png",
    linkText: "Learn more"
  },
  {
    title: "Digital Events",
    description: "Make your digital lunch and learns, webinars and client meetings more delicious with food!",
    link:"/meals-for-digital-events/",
    imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626796618/Grubhub%20Corporate%20Website/2021/Photography%20Elements/2-OrderingOccassion-Homepage/EXPORTS-Photography-CorpOccasionsHomepage-450x220-DigitalEvents.png",
    linkText: "Learn more"
  },
  {
    title: "Remote Employees",
    description: "Show your remote team you care with customizable meal plans from Grubhub.",
    link:"/meals-for-remote-employees/",
    imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626796617/Grubhub%20Corporate%20Website/2021/Photography%20Elements/2-OrderingOccassion-Homepage/EXPORTS-Photography-CorpOccasionsHomepage-450x220-RemoteEmployees.png",
    linkText: "Learn more"
  }
]

const OrderingOccasions = ({ data, location }) => {
  return (
    <Layout
      location={location}
      title={"Corporate Ordering Occasions | Grubhub Corporate"}
      description={"From virtual events to remote employees, Grubhub's got every food ordering occasion covered."}
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "ordering occasions",
        pageName: "ordering occasions",
        brand: "grubhub"
      }}
    >
      <IllustrationHeader
        headline="Flexible meal benefits for any occasion"
        subheadline="Delight team members, employees and clients at your next in-person or virtual event."
        background="radial-gradient(circle, #612889 0%, var(--color-primary-darker) 100%)"
        leftSideIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1628277582/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/3-ReasonsToOrder-Homepage/DESKTOP-FeedEveryOccassion-LEFT_IMAGE.png"
        altLeftSide="Illustration of a pizza with two pieces of bacon and a fried egg flying over it."
        rightSideIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1652234741/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Ordering%20Occasions/DESKTOP-HowitWorks-FeedEveryOccassion-RIGHT_IMAGE.png"
        altRightSide="Illustration of an orange Grubhub food delivery bag."
        mobileIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1652368104/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/Ordering%20Occasions/MOBILE-OrderingOccasions-HeaderImage.png"
        altMobile="Illustration of an orange Grubhub food delivery bag."
        />
      <CardsSection cards={cards} cardsPerRow={2}/>
      <QuoteSection
        quote="Using the corporate features of Grubhub has been instrumental in providing a bit of happiness to our employees during these chaotic times. It's amazing what a meal can do for morale and the team has been grateful for such a sincere gift!"
        author="Meghan M"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-Compass.png"
        alt="Compass logo"
        position="Office Administrator at Compass"
      />
      <ValuePropsSection
        headline="Delivering happiness is our #1 priority"
        layout="horizontal"
        backgroundColor="#F9F9F9"
        valueProps={[
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORTS-Illustrations-CheckIcon.svg",
            headline:"Easiest for employees",
            subheadline:"Ordering is simple and easy. There’s no learning curve, which means your team will enjoy ordering good food from day one. Plus, Grubhub has the most ways to order, so your team can order whenever and however they want."
          },
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORTS-Illustrations-CheckIcon.svg",
            headline:"Best for office managers",
            subheadline:"The most group ordering options. Controlled, reduced costs. Guaranteed on-time delivery at the lowest price or we make it right. And, get added peace of mind with safe delivery options like Curbside Pickup and Contact-Free Delivery."
          },
          {
            imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORTS-Illustrations-CheckIcon.svg",
            headline:"Built for businesses",
            subheadline:"Since 1999, Grubhub Corporate has been proudly serving corporate clients across America. The first to feed a hybrid workforce, we bring you cost-effective and flexible solutions that fit your team’s evolving needs."
          }
        ]}
      />
      <CtaSection
        headline="Discover the industry leader in flexible meal benefits"
        ctaText="Get started"
        to="/get-started/"
      />
    </Layout>
  )
}

export default OrderingOccasions
