import { Cta, Headline, StyledCtaSection } from "./CtaSection.styles"
import React, { useContext } from 'react';

import { AnalyticsContext } from "../../../context";
import PropTypes from 'prop-types';
import { getPageSlug } from '../../../../helpers';

const CtaSection = (props) => {
  const { handleTealiumEvent } = useContext(AnalyticsContext)

  return(
  <StyledCtaSection backgroundColor={props.backgroundColor}>
    <Headline>{props.headline}</Headline>
    <Cta 
      to={props.to}
      type={props.buttonType}
      onClick={() => handleTealiumEvent({ eventcategory: `${props.eventcategory}`, eventaction: `${props.eventaction}`, eventlabel: `${props.eventlabel}` })}
    >
      {props.ctaText}
    </Cta>
  </StyledCtaSection>
  )
};

CtaSection.propTypes = {
  headline: PropTypes.string,
  to: PropTypes.string,
  ctaText: PropTypes.string,
  backgroundColor: PropTypes.string,
  buttonType: PropTypes.string,
  eventcategory: PropTypes.string,
  eventaction: PropTypes.string,
  eventlabel: PropTypes.string,

};

CtaSection.defaultProps = {
  headline: "",
  to: "/get-started/",
  ctaText: "Get started",
  backgroundColor: null,
  buttonType: "primary",
  eventcategory: `${getPageSlug()} interaction`,
  eventaction: "get started_cta",
  eventlabel: "see what we can do for you",
};

export default CtaSection;
