import styled, { css } from 'styled-components';

import { queries } from "../../../breakpoints"

export const Headline = styled.h2`
    text-align: center;
    font-weight: bold;
`;

export const Subheadline = styled.p`
    text-align: center;
    font-size: var(--fontSize-2);
    color: var(--color-text-light);
`;

export const Row = styled.div`
    margin-top: 40px;
    justify-content: space-between;
`;

export const ValuePropHeadline = styled.h5`
    font-weight: bold;
    margin-top: 15px;
    @media(${queries.max_break_md}){
        margin-top: initial;
    }
`;

export const ValuePropImage = styled.img`
    margin-bottom: 15px;
    max-height: 165px;
    width: auto;
    max-width: 100%;
`;

export const ValuePropContent = styled.div`
    padding-right: 15px;
    @media(${queries.max_break_md}){
        padding-right: 0px;
    }
`;

export const StyledValueProp = styled.article`
    ${props => props.layout === "vertical" ? 
        css`
            text-align: center;
            max-width: 300px;
            padding: 0 10px;
            margin: 15px auto;
        ` :
        // Horizontal layout styles
        css`
            display: flex;
            margin-bottom: 35px;
            ${ValuePropHeadline}{
                font-size: var(--fontSize-2);
            }
            ${ValuePropImage}{
                margin-right: 10px;
                max-width: 110px;
                @media(${queries.max_break_md}){
                    margin-right: 0px;
                }
            }
            ${ValuePropContent}{
                display: inline;
            }
        `    
    }
    @media (${queries.max_break_md}){
        ${props => props.layout === "vertical" ? 
        css`
        //Vertical layout styles
        ` :
        // Horizontal layout styles
        css`
            flex-flow:column;
            text-align:center;
            ${ValuePropContent}{
                max-width: 300px;
                margin: 0 auto;
            }
        `    
    }
    }
`;