import styled from 'styled-components';
import SectionWrapper from '../SectionWrapper';
import Button from '../../global/Button';

export const StyledCtaSection = styled(SectionWrapper)`
    text-align: center;
`;

export const Headline = styled.h2`
    font-weight: bold;
    margin-bottom:20px;
`; 

export const Cta = styled(Button)`
    // Styles here are not working... Something in the Button setup is not allowing the styles to be extended
`;